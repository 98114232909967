import { render, staticRenderFns } from "./AutumnSchool.vue?vue&type=template&id=ef3f8920&scoped=true&"
import script from "./AutumnSchool.vue?vue&type=script&lang=js&"
export * from "./AutumnSchool.vue?vue&type=script&lang=js&"
import style0 from "./AutumnSchool.vue?vue&type=style&index=0&id=ef3f8920&prod&lang=scss&scoped=true&"
import style1 from "./AutumnSchool.vue?vue&type=style&index=1&id=ef3f8920&prod&lang=scss&scoped=true&"
import style2 from "./AutumnSchool.vue?vue&type=style&index=2&id=ef3f8920&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef3f8920",
  null
  
)

export default component.exports